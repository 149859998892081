fancyboxStart = function (e) {
    $(e).siblings('.gallery-can').find('a:first').click();
};

if (!_HANDYMAN_) {
    var _HANDYMAN_ = {};
}

_HANDYMAN_.switchLang = function (lang) {
    $.cookie("language", lang);
    location.reload(true);//force reload form server, instead of from cache.
};

_HANDYMAN_.saveOrderProjects = function () {
    var data = $('.table-projects-list>tbody').sortable('serialize');
    console.log(data);

    $.ajax({
        method: "POST",
        data: data,
        url: '/admin/projects/order',
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.saveOrderFrontImages = function () {
    var data = $('.table-images-list>tbody').sortable('serialize');
    console.log(data);

    $.ajax({
        method: "POST",
        data: data,
        url: '/admin/frontimages/order',
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.saveOrderImagesForProject = function (id) {
    var data = $('.table-images-list>tbody').sortable('serialize');

    $.ajax({
        method: "POST",
        data: data,
        url: '/admin/projects/images/' + id,
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.dissociateImages = function () {
    var fdata = {};
    var id_array = [];
    $('.image-checkbox:checked').each(function () {
        id_array.push($(this).val());
    });
    fdata.ids = id_array.join(',');

    $.ajax({
        method: "POST",
        url: '/admin/images/dissociateImages',
        data: fdata,
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.deleteImages = function () {
    var fdata = {};
    var id_array = [];
    $('.image-checkbox:checked').each(function () {
        id_array.push($(this).val());
    });
    if(id_array.length==0)return;
    fdata.ids = id_array.join(',');

    $.ajax({
        method: "POST",
        url: '/admin/images/delete',
        data: fdata,
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.deleteFrontimages = function () {
    var fdata = {};
    var id_array = [];
    $('.image-checkbox:checked').each(function () {
        id_array.push($(this).val());
    });
    if(id_array.length==0)return;
    fdata.ids = id_array.join(',');

    $.ajax({
        method: "POST",
        url: '/admin/frontimages/delete',
        data: fdata,
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};


_HANDYMAN_.addToProject = function () {
    var project_id = $('select[name="project_id"]').val();
    var id_array = [];
    $('.image-checkbox:checked').each(function () {
        id_array.push($(this).val());
    });
    if (project_id == 0 || id_array.length == 0) {
        return;
    }

    var fdata = {};
    fdata.ids = id_array.join(',');
    fdata.project_id = project_id;
    $.ajax({
        method: "POST",
        url: '/admin/images/addToProject',
        data: fdata,
        dataType: 'jsonp',
        jsonpCallback: '_HANDYMAN_.jsonResult'
    });
};

_HANDYMAN_.jsonResult = function (d) {
    console.log(d);
    if (d.status == 'SUCCESS') {
        if (d.action == 'deleteImages' || d.action == "dissociateImages" || d.action == "saveOrderProjects"|| d.action=="saveOrderFrontImages" ||d.action == "saveOrderImagesForProject") {
            location.reload();
        }
        else if (d.action == "addToProject") {
            var project_id = d.data.project_id;
            window.location.href ='/admin/projects/images/'+project_id;
        }
    }
};

$(function () {
    $body = $('body');
    $('.btn-manage-uploaded').hide();

    $('.btn-manage-uploaded').click(function () {
        location.reload();
    });

    $('#images-table').DataTable({"ordering": false, "paging": false});


    Dropzone.options.myDropzone = {
        init: function () {
            this.on("success", function (file, responseText) {
                //hint for a refresh.
                $('#my-dropzone').closest('.row').find('.btn-manage-uploaded').show();

                //setTimeout(function () {
                //    location.reload();
                //}, 2000);
            });
        }
    };
    Dropzone.options.myDropzoneFp = {
        init: function () {
            //hint for a refresh.
            this.on("success", function (file, responseText) {
                $('#my-dropzone-fp').closest('.row').find('.btn-manage-uploaded').show();

                //setTimeout(function () {
                //    location.reload();
                //}, 3000);
            });
        }
    };

    $('a[href*=#]:not([href=#])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st <= 220) {
            $(".sc-header-blog").fadeIn("slow");
            return;
        }

        if (st > lastScrollTop) {
            $(".sc-header-blog").delay(100).fadeOut("slow");  //hide
        } else {
            $(".sc-header-blog").delay(100).fadeIn("slow");   //show
        }
        lastScrollTop = st;
    });

    $('.sc-btn-rocket').click(function () {
        $('html,body').animate({
            scrollTop: $('.main-body').offset().top
        }, 1000);
    });

    $(".home-sec-carousel").owlCarousel({
        //transitionStyle : "fade",
        autoPlay: 6000, //Set AutoPlay to 3 seconds
        //items : 1
        //navigation : true,
        singleItem : true,
        transitionStyle : "fade"
    });

    $('.fancybox').fancybox({
        'type': 'image',
        'autoSize': true,
        // 'cyclic':true,
        'titlePosition': 'over',
        'transitionIn': 'elastic',
        'transitionOut': 'elastic',
        //lock the background when fancybox is active so weird padding doesn't show up
        helpers: {
            overlay: {
                locked: false
            }
        }
    });

    $('img.lazy').lazyload();
    $('.table-projects-list>tbody').sortable();
    $('.table-images-list>tbody').sortable();

    $("#image-checkbox-main").click(function () {
        if ($('#image-checkbox-main').is(":checked")) {
            $('.image-checkbox').each(function (index, element) {
                $(this).prop('checked', true);
            });
        } else {
            $('.image-checkbox').each(function (index, element) {
                $(this).prop('checked', false);
            });
        }
    });

});